import type { Account } from "@/models";
import { createDateRangeArray } from "@/utils/createDateRangeArray";
import { type DayJsInput, maxSafeDateMs, UTC } from "@/utils/UTC";
import { allBrokers } from "~/components/BrokerAnalysis/Comparison/allBrokers";
import type { DataItemValue } from "~/components/BrokerAnalysis/types";

import { useAppStore } from "./app";
import type { DateStoreDefinitionSetup } from "./date.types";
import { useDate } from "./useDate";

export type UseDateBrokerAnalysis = typeof useBrokerAnalysis;

export const useBrokerAnalysis = defineStore("brokerAnalysis", () => {
  const { accounts } = storeToRefs(useAppStore());

  const yesterday = UTC().subtract(1, "day");

  const inceptionDate = computed<string>(
    () =>
      accounts.value
        .map((e) => e.start_date)
        .filter(Boolean)
        .sort((a, b) => +UTC(a) - +UTC(b))
        .at(0) ?? yesterday.format("YYYY-MM-DD")
  );

  /*
   * Latest date any pipeline has data.
   *  - Defaults to yesterday if no data received
   *  - Cannot display todays date
   */

  function accountPipelineHasRunOnDate(date: string) {
    return (account: Account) =>
      // Account is active on date
      +UTC(account.end_date ?? maxSafeDateMs) >= +UTC(date) &&
      // Account has run pipeline
      !account.missing_dates.includes(date);
  }

  const latestDate = computed<string>(() => {
    const dateRange = createDateRangeArray(inceptionDate.value, yesterday);

    const latestAccountDate = dateRange.findLast((date) =>
      accounts.value.find(accountPipelineHasRunOnDate(date))
    );

    return latestAccountDate ?? toISOstring(yesterday); //endDate.value;
  });

  const { startDate, endDate, selectedDate, cachedDateRange, setPeriod } =
    useDate();

  watchEffect(() => {
    selectedDate.value = latestDate.value;
  });

  const allowedDateMinMs = computed(() => +UTC(inceptionDate.value));
  const allowedDateMaxMs = computed(() => +UTC(latestDate.value));

  function isDisabledDate(date: DayJsInput) {
    const dateMs = +UTC(date).startOf("day");
    return dateMs < allowedDateMinMs.value || dateMs > allowedDateMaxMs.value;
  }

  const period = useSetting<"quarter" | "year">("period", {
    default: "quarter",
    section: "broker_review",
  });

  watchEffect(() => {
    startDate.value = toISOstring(
      UTC(selectedDate.value).startOf(period.value).subtract(3, period.value)
    );
  });

  watchEffect(() => {
    endDate.value = toISOstring(UTC(selectedDate.value).endOf(period.value));
  });

  const isAnnual = computed(() => period.value === "year");

  const selectedColumnTypes = ref<DataItemValue[]>([
    "projected_value",
    "rank",
    "period_to_date_value",
    "percent_of_total",
  ]);

  const appStore = useAppStore();
  const { availablePrimeBrokers, envMeta } = storeToRefs(appStore);

  const focusedBroker = useSetting("focusedBroker", {
    default: availablePrimeBrokers.value[0],
    section: "broker_review",
    validator: isOneOf(...availablePrimeBrokers.value),
  });

  const isDummyData = ref(false);
  // If on Demo environment default to use dummy data for strategies
  watchEffect(() => {
    isDummyData.value = envMeta.value.client === "Demo";
  });

  const brokers = computed(() =>
    isDummyData.value ? allBrokers : availablePrimeBrokers.value
  );

  const selectedBrokers = useSetting<ApiFilterItem[]>("selectedBrokers", {
    default: toValue(brokers).slice(1),
    section: "broker_review",
    validator: (value, _type) =>
      Array.isArray(value) &&
      value.every((e) =>
        isOneOf(...availablePrimeBrokers.value)(e, _type?.[0])
      ),
  });

  watch(isDummyData, () => {
    focusedBroker.value = brokers.value[0];
    selectedBrokers.value = toValue(brokers).slice(1);
  });

  const isAnonymisedBrokerData = useSetting("isAnonymisedBrokerData", {
    default: false,
    section: "broker_review",
  });
  const isAnonymisedBrokerName = useSetting("isAnonymisedBrokerName", {
    default: false,
    section: "broker_review",
  });

  const dates = computed(() =>
    createDateMsRangeArray(startDate.value, endDate.value)
  );

  const randomArr = computed(() =>
    Array.from({ length: Math.floor(dates.value.length / 30) }, () => {
      return Math.floor(Math.random() * dates.value.length);
    })
  );

  const availablePeriods = ref<string[]>([]);

  return {
    allowedDateMax: latestDate,
    allowedDateMin: inceptionDate,
    cachedDateRange,
    endDate,
    selectedDate,
    startDate,
    isDisabledDate,
    setPeriod,
    //
    period,
    isAnnual,
    focusedBroker,
    selectedBrokers,
    isAnonymisedBrokerName,
    isAnonymisedBrokerData,
    selectedColumnTypes,
    randomArr,
    availablePeriods,
    isDummyData,
    brokers,
  };
}) satisfies DateStoreDefinitionSetup;
