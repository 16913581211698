<template>
  <v-list-item @click="isSuperuserOverride = !isSuperuserOverride">
    <div class="flex items-center justify-between">
      <v-list-item-title class="text-gray-500">
        View as {{ isSuperuserOverride ? "user" : "admin" }}
      </v-list-item-title>
      <v-icon
        class="mr-1"
        size="small"
        :icon="isSuperuserOverride ? mdiAccount : mdiAccountStar"
      />
    </div>
  </v-list-item>
  <v-list-group
    v-if="isSuperuserOverride"
    :expand-icon="mdiChevronRight"
    :collapse-icon="mdiChevronRight"
    class="mb-4"
    color="primary"
    aria-label="Debug"
    role="listitem"
  >
    <template #activator="{ props: activatorProps }">
      <v-list-item
        v-bind="activatorProps"
        title="Debug"
      >
        <template #append>
          <v-icon
            class="toggle-icon mr-1"
            size="small"
          >
            {{ mdiChevronRight }}
          </v-icon>
        </template>
      </v-list-item>
    </template>

    <AppSettingsMultiSelect
      v-model="debugSubscriptions"
      label="Subscriptions"
      :items="debugSubscriptionOptions"
      :track-event="undefined"
    />

    <AppSettingsMultiSelect
      v-model="selectedFeatureFlags"
      label="Feature Flags"
      :items="featureFlagOptions"
      :track-event="undefined"
    />

    <AppSettingsCheckbox
      v-model="logTrackEventsToConsole"
      label="Log Track Events To Console"
      :track-event="undefined"
    />
    <AppSettingsCheckbox
      v-model="logTrackEventsToAws"
      label="Log Track Events to AWS"
      :track-event="undefined"
    />
    <AppSettingsCheckbox
      v-model="isStaffOverride"
      label="Staff User"
      :track-event="undefined"
    />
    <AppSettingsCheckbox
      v-model="isBrokerAnalysisDummyData"
      label="Broker Review Dummy Data"
      :track-event="undefined"
    />
    <AppSettingsCheckbox
      v-model="isDummyStrategyData"
      label="Strategies Dummy Data"
      :track-event="undefined"
    />
    <v-list-group
      :expand-icon="mdiChevronRight"
      :collapse-icon="mdiChevronRight"
      aria-label="Backend Feature Flags"
      role="listitem"
    >
      <template #activator="{ props: activatorProps }">
        <v-list-item v-bind="activatorProps">
          <v-list-item-title class="opacity-60"
            >Backend Feature Flags</v-list-item-title
          >
          <template #append>
            <v-icon
              class="toggle-icon mr-1"
              size="small"
            >
              {{ mdiChevronRight }}
            </v-icon>
          </template>
        </v-list-item>
      </template>
      <v-list-item
        v-for="(value, key) in envMeta.feature_flags"
        :key
      >
        <UiCheckbox
          v-list-item
          :model-value="value"
          :track-event="undefined"
          :readonly="true"
        >
          <template #label>
            <div>
              <div class="text-sm">{{ toTitle(key).replace("S 3", "S3") }}</div>
              <div class="text-xs">{{ key }}</div>
            </div>
          </template>
        </UiCheckbox>
      </v-list-item>
    </v-list-group>
  </v-list-group>
  <hr class="m-2" />
</template>

<script setup="setup" lang="ts">
import { mdiAccount, mdiAccountStar, mdiChevronRight } from "@mdi/js";
import { VIcon, VListGroup, VListItem } from "vuetify/lib/components/index.mjs";

import { useAppStore } from "@/store/app";
import { toTitle } from "#imports";
import { availableSubscriptions } from "~/store/app.types";
import { useBrokerAnalysis } from "~/store/brokerAnalysis";

import AppSettingsCheckbox from "./AppSettingsCheckbox.vue";
import AppSettingsMultiSelect from "./AppSettingsMultiSelect.vue";

const {
  subscriptions,
  isSuperuserOverride,
  isStaffOverride,
  featureFlags,
  logTrackEventsToConsole,
  logTrackEventsToAws,
  envMeta,
  isDummyStrategyData,
} = storeToRefs(useAppStore());

const { isDummyData: isBrokerAnalysisDummyData } =
  storeToRefs(useBrokerAnalysis());

const debugSubscriptionOptions: { title: string; value: Subscription }[] =
  availableSubscriptions.map((value) => ({ value, title: toTitle(value) }));

const debugSubscriptions = ref(
  debugSubscriptionOptions
    .map((e) => e.value)
    .filter((e) => subscriptions.value[e])
);

watch(debugSubscriptions, (values) => {
  objectKeys(subscriptions.value).forEach((key) => {
    subscriptions.value[key] = values.includes(key);
  });
});

const featureFlagOptions: { title: string; value: FeatureFlag }[] = [
  { title: "Trade Date Data", value: "showTradeDateData" },
  { title: "New Analysis Components", value: "showNewAnalysisComponents" },
  {
    title: "Disable Strategies Positions",
    value: "disableStrategiesPositions",
  },
];

const selectedFeatureFlags = ref<FeatureFlag[]>(
  featureFlagOptions.map((e) => e.value).filter((e) => featureFlags.value[e])
);

watch(selectedFeatureFlags, (values) => {
  objectKeys(featureFlags.value).forEach((key) => {
    featureFlags.value[key] = values.includes(key);
  });
});
</script>
