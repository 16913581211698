import { captureException, captureMessage } from "@sentry/vue";
import type { AwsRumConfig } from "aws-rum-web";
import { AwsRum } from "aws-rum-web";

import { printFormattedMessage } from "@/utils/printFormattedMessage";
import { useAppStore } from "~/store/app";

// eslint-disable-next-line import/no-mutable-exports
export let awsRum: AwsRum | null = null;

export function useAwsRum() {
  const appStore = useAppStore();
  if (awsRum) {
    return { awsRum };
  }
  const userProfile = appStore.userProfile;
  if (userProfile === undefined) {
    captureMessage("useAwsRum: userProfile is undefined");
    return { awsRum: null };
  }

  const runTimeConfig = useRuntimeConfig();
  const { guestRoleArn, identityPoolId, rumApplicationId } =
    runTimeConfig.public;

  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    guestRoleArn,
    identityPoolId,
    endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
    telemetries: ["performance", "errors", "http"],
    allowCookies: true,
    enableXRay: false,
    dispatchInterval: 5000,
    sessionEventLimit: 0,
    disableAutoPageView: true,
    sessionAttributes: {
      email: userProfile.email,
      is_staff: Boolean(userProfile.is_staff),
    },
  };

  try {
    awsRum = new AwsRum(rumApplicationId, "1.0.0", "eu-west-1", config);
    if (appStore.logTrackEventsToAws) {
      // recordPageView will not trigger unless the pageId has changed
      awsRum.disable();
      awsRum.recordPageView("initial");
      awsRum.enable();
    }
  } catch (error) {
    console.error(error);
    // Send error to Sentry
    captureException(error);
  }

  printFormattedMessage([
    {
      text: `AWS RUM Web client | ${rumApplicationId}`,
      style: "background-color: #FF9900; color: black;",
    },
    {
      text: awsRum ? "Loaded" : "Not Loaded",
      style: "background-color: black; color: #FF9900;",
    },
  ]);

  return { awsRum };
}
