<template>
  <div
    class="overflow-hidden text-ellipsis px-4 leading-[calc(var(--row-height)-1px)]"
    :class="{ 'text-gray-500': value === 'Multiple' }"
  >
    {{ value }}
  </div>
</template>

<script setup lang="ts">
import type { DataTableColumn, DataTableItem } from "../dataTableTypes";

const props = defineProps<{
  column: DataTableColumn;
  item: DataTableItem;
  formatter?: (value: any) => string;
}>();

const value = computed(() => {
  const raw = props.item[props.column.value];

  return props.formatter ? props.formatter(raw) : raw;
});
</script>

<script lang="ts">
export function getGroupValueUnique(
  items: DataTableItem[],
  column: DataTableColumn<any>
) {
  return isUnique(items, (e) => e[column.value])
    ? items.at(0)?.[column.value]
    : "Multiple";
}
</script>
