<template>
  <NuxtLink
    v-if="showItem"
    :to
    :external
  >
    <v-list-item
      :title
      class="h-12 text-primary"
      :aria-label="title"
      :active="isActive"
    >
      <template #prepend>
        <Transition :name="isCollapsing ? 'slide-reverse' : 'slide'">
          <div
            v-if="beta && props.icon !== mdiCircleMedium && isCollapsed"
            class="relative"
          >
            <BetaChip class="position-absolute left-[-12px] top-[-12px]" />
          </div>

          <v-icon
            v-else
            size="small"
          >
            {{ icon }}
          </v-icon>
        </Transition>
      </template>
      <template #append>
        <BetaChip
          v-if="beta && props.icon !== mdiCircleMedium"
          :class="{ 'mr-1': showBadge }"
        />
        <div
          v-if="showBadge"
          class="flex h-6 w-8 items-center justify-center rounded-full"
          :style="{ backgroundColor: badgeColor }"
        >
          <v-icon
            size="x-small"
            :class="`${toKebab(title)}-icon`"
            :aria-label="badgeLabel"
            aria-hidden="false"
            color="white"
          >
            {{ badgeIcon }}
          </v-icon>

          <v-tooltip
            location="top"
            :activator="`.${toKebab(title)}-icon`"
            open-delay="400"
          >
            {{ badgeLabel }}
          </v-tooltip>
        </div>

        <slot name="append" />
      </template>

      <slot name="default" />
    </v-list-item>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { mdiAccountLock, mdiAccountStar, mdiCircleMedium } from "@mdi/js";

import { toKebab } from "#imports";
import { useAppStore } from "~/store/app";

import { isCollapsedInjectionKey } from "./navDrawerInjectionKeys";

const props = withDefaults(
  defineProps<{
    to?: string;
    external?: boolean;
    title: string;
    icon?: string;
    adminOnly?: boolean;
    subscription?: Subscription;
    beta?: boolean;
  }>(),
  {
    icon: mdiCircleMedium,
  }
);

const { subscriptions, isSuperuserOverride } = storeToRefs(useAppStore());

const showItem = computed(() => isSuperuserOverride.value || !props.adminOnly);

const isSubscribed = computed(
  () =>
    !props.subscription ||
    subscriptions.value[props.subscription] ||
    isSuperuserOverride.value
);

const showBadge = computed(() => props.adminOnly || !isSubscribed.value);

const badgeIcon = computed(() =>
  props.adminOnly ? mdiAccountStar : mdiAccountLock
);

const badgeLabel = computed(() =>
  props.adminOnly ? "Admin only" : "Subscription needed to access module"
);

const badgeColor = computed(() =>
  badgeIcon.value === mdiAccountStar ? theme.accent : theme.primary
);

const route = useRoute();

const isActive = computed(() => route.fullPath === props.to);

const isCollapsed = inject(isCollapsedInjectionKey) as Ref<boolean>;

const isCollapsing = ref(false);
watch(isCollapsed, (isCollapsed) => {
  if (!isCollapsed) {
    isCollapsing.value = false;
    return;
  }

  isCollapsing.value = true;

  setTimeout(() => (isCollapsing.value = false), 200);
});
</script>

<style scoped>
.v-list-item {
  height: 2.75rem;
}
:deep(.v-list-item-title) {
  font-size: 11px !important;
  letter-spacing: 1.75px;
}

:deep(.v-list-item__content) {
  line-height: 1rem;
}

:deep(.v-list-item__prepend > .v-icon) {
  margin-left: -2px;
}

.v-list-item:hover {
  background-color: color-mix(in srgb, currentColor 5%, white);
}

.slide-enter-active,
.slide-leave-active,
.slide-reverse-enter-active,
.slide-reverse-leave-active {
  transition: all 0.2s ease;
}

.slide-reverse-leave-to,
.slide-enter-from {
  opacity: 0;
  transform: translateX(-40px);
}

.slide-reverse-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateX(40px);
}

.slide-leave-active,
.slide-leave-to,
.slide-reverse-leave-active,
.slide-reverse-leave-to {
  position: absolute;
}
</style>
