import type { Account } from "@/models/account";
import type { StockLoanBenchmarkingPercentiles } from "@/models/stock-loan-benchmarking-percentiles";
import type { Tiers } from "@/models/tiers";
import type { FxData } from "~/server/routes/api-v2/fx-rates";

import { views } from "./app.types";
import type { DateQuickSelectOption } from "./useDate";

export type AppStore = ReturnType<typeof useAppStore>;

export type AppSettings = {
  selectedView: FilterGroup | "field";
  splitBy: FilterGroup | "field";
  defaultDateRange: string;
  isMultisort: boolean;
  showPrecision: boolean;
  tiering: "kst" | "prime_broker";
  showPositionCurrency: boolean;
  zoomYAxis: boolean;
};

export const useAppStore = defineStore("app", () => {
  const envMeta = ref<EnvMeta>({
    client: "",
    environment: "",
    kinde_org_id: "",
    version: "",
    release_timestamp: "",
    optimizations_latest_available_fis_date: "2021-01-01",
    feature_flags: {},
  });

  const cachedData = ref<Dictionary>({});

  /** Only for internal use by FilterControls component. Use `globalFilterParams` instead. */
  const globalFilters = ref<Record<FilterGroup, string[]>>({
    prime_broker: [],
    fund: [],
    account: [],
    currency: [],
  });

  const strategyGlobalFilters = ref<Record<StrategyFilterGroup, string[]>>({
    prime_broker: [],
    fund: [],
    account: [],
    currency: [],
    strategy: [],
    strategy_account: [],
  });

  const isSetupComplete = ref(false);

  const pendingRequests = ref<Set<string>>(new Set());

  const { isLocal } = useRuntimeConfig().public;

  const logTrackEventsToConsole = ref(false);
  const logTrackEventsToAws = ref(!isLocal);
  const featureFlags = ref<Record<FeatureFlag, boolean>>({
    showTradeDateData: false,
    showNewAnalysisComponents: false,
    disableStrategiesPositions: false,
  });

  // Load feature flags from envMeta
  watchEffect(() => {
    featureFlags.value.showTradeDateData =
      envMeta.value.feature_flags.show_trade_date_data;
    featureFlags.value.showNewAnalysisComponents =
      envMeta.value.feature_flags.use_nuxt_summary_apis;
  });

  //Add temporary item with predefined color, so other calls to itemsWithColor are consistent
  const tempItemWithColor = ref<SelectItem[]>([]);
  const itemsWithColor = computed<(SelectItem | StrategyApiFilterItem)[]>(
    () => {
      return [
        ...availableAccounts.value,
        ...availableCurrencies.value,
        ...availableFunds.value,
        ...availablePrimeBrokers.value,
        ...availablePbTiers.value,
        ...availableKsTiers.value,
        ...availableStrategies.value,
        ...availableStrategyAccounts.value,
        ...tempItemWithColor.value,
      ];
    }
  );
  /**
   * Returns a color for an item based on its title
   * Provide an index to get a consistent spread of alternative colors if the item is not available
   */
  function getItemColor(
    title: string,
    index?: number,
    colorArray: string[] = itemColors
  ) {
    index ??= Math.floor(Math.random() * colorArray.length);
    let color = itemsWithColor.value.find(
      (item) => item.title === title
    )?.color;

    // If no color, create one using index
    if (!color) {
      color = colorArray[index % colorArray.length];
      // Save color so will be consistent with other calls to the function
      tempItemWithColor.value.push({
        title,
        value: title,
        color,
      });
    }
    return color;
  }

  const defaultDateRange = ref<DateQuickSelectOption["title"]>("Last 365 Days");

  const selectedView = ref<FilterGroup | "field">("prime_broker");
  const strategySelectedView = ref<StrategyViewGroupBy>("strategy");

  const splitBy = ref<FilterGroup | "field">("fund");
  const strategySplitBy = ref<StrategyFilterGroup | "field">("prime_broker");

  const isMultisort = ref(true);
  const tiering = ref<AppSettings["tiering"]>("kst");
  const zoomYAxis = ref(true);

  const showPositionCurrency = ref(false);

  const showPrecision = ref(false);

  function usePrecision([standard, full]: [number, number]) {
    return showPrecision.value ? full : standard;
  }

  const graphSmoothing = ref<number>(1);

  // Global filter items *************************

  const activePrimeBrokers = computed(() => {
    const activeFilters = Object.values(
      omit(globalFilters.value, ["currency"])
    );

    const activeRelationships = groupRelationships.value.filter(
      (relationship) =>
        activeFilters
          .filter((values) => values.length)
          .every((values) => values.some((val) => relationship.includes(val)))
    );
    return unique(activeRelationships.map(([, , pb]) => pb));
  });

  function drilldownView(itemName: string) {
    if (selectedView.value === "field") return;

    globalFilters.value[selectedView.value] = [itemName];

    const currentIndex = views.findIndex(
      (e) => e.groupBy === selectedView.value
    );
    // Skip aggregate view when using drilldown
    const nextIndex = (currentIndex + 1) % (views.length - 1);
    selectedView.value = views[nextIndex].groupBy;
  }

  function uniqueItems<T>(item: T, index: number, array: T[]) {
    return array.findIndex((e) => deepEqual(e, item)) === index;
  }

  function sortByAliasThenTitle<T extends { title: string; alias?: string }>(
    a: T,
    b: T
  ) {
    const conditions = [
      (a.alias ?? "").localeCompare(b.alias ?? ""),
      a.title.localeCompare(b.title),
    ];

    return conditions.find(Boolean) ?? 0;
  }

  function createSelectItems(
    items: WithRequired<Partial<SelectItem>, "title">[],
    group: FilterGroup
  ): ApiFilterItem[];
  function createSelectItems(
    items: WithRequired<Partial<SelectItem>, "title">[],
    group: StrategyFilterGroup
  ): StrategyApiFilterItem[];
  function createSelectItems(
    items: WithRequired<Partial<SelectItem>, "title">[],
    group: FilterGroup | StrategyFilterGroup
  ): ApiFilterItem[] | StrategyApiFilterItem[] {
    return items
      .filter(uniqueItems)
      .sort(sortByAliasThenTitle)
      .map((item, index) => ({
        value: item.title,
        group,
        color: itemColors[index % itemColors.length],
        ...item,
      }));
  }

  const availablePrimeBrokers = computed<ApiFilterItem[]>(() => {
    const primeBrokers = unique(accounts.value.map((e) => e.prime_broker));

    return createSelectItems(primeBrokers, "prime_broker");
  });

  const availableAccounts = computed<ApiFilterItem[]>(() => {
    const _accounts = accounts.value.map((e) => ({
      title: e.title,
      alias: e.type === "cash_pb" ? "Cash" : "Swap",
    }));

    return createSelectItems(_accounts, "account");
  });

  const availableFunds = computed<ApiFilterItem[]>(() => {
    const funds = unique(accounts.value.map((e) => e.fund)).map((e) => ({
      title: e.title,
      alias: e.alias === e.title ? undefined : e.alias,
    }));

    return createSelectItems(funds, "fund");
  });

  const availableCurrencies = computed(() => {
    const currencies = unique(
      Object.values(fxRates.value ?? {}).flatMap((e) => Object.keys(e))
    ).map((e) => ({ title: e }));

    return createSelectItems(currencies, "currency");
  });

  const availableStrategies = computed<StrategyApiFilterItem[]>(() => {
    const strategies = unique(
      strategyAccounts.value.map((account) => account.strategy),
      (e) => e.title
    );

    return createSelectItems(strategies, "strategy");
  });

  const availableStrategyAccounts = computed<StrategyApiFilterItem[]>(() => {
    const arr = strategyAccounts.value.map((account) => ({
      ...account,
      alias: account.type === "cash_pb" ? "Cash" : "Swap",
    }));
    return createSelectItems(arr, "strategy_account");
  });

  const availableFilters = computed<Record<FilterGroup, ApiFilterItem[]>>(
    () => ({
      prime_broker: availablePrimeBrokers.value,
      fund: availableFunds.value,
      account: availableAccounts.value,
      currency: availableCurrencies.value,
    })
  );
  const strategyAvailableFilters = computed<
    Record<StrategyFilterGroup, StrategyApiFilterItem[]>
  >(() => ({
    prime_broker: availablePrimeBrokers.value,
    fund: availableFunds.value,
    account: availableAccounts.value,
    currency: availableCurrencies.value,
    strategy: availableStrategies.value,
    strategy_account: availableStrategyAccounts.value,
  }));

  const globalCurrency = computed(() =>
    globalFilters.value.currency.length === 1
      ? globalFilterParams.value.currency[0]
      : "USD"
  );

  const isSingleCurrencyFilter = computed(() => {
    return globalFilters.value.currency.length === 1;
  });
  const strategyIsSingleCurrencyFilter = computed(() => {
    return strategyGlobalFilters.value.currency.length === 1;
  });

  /** Combines account filters to single array and treats 'full' arrays as empty */
  const globalFilterParams = computed<Record<FilterGroup, string[]>>(() => {
    const entries = objectEntries(globalFilters.value).map(
      ([group, values]) => {
        const totalCount = availableFilters.value[group].length;

        // Spread values to prevent result being a Proxy object
        return [group, values.length === totalCount ? [] : [...values]];
      }
    );

    return Object.fromEntries(entries);
  });

  const strategyGlobalFilterParams = computed<
    Record<StrategyFilterGroup, string[]>
  >(() => {
    const entries = objectEntries(strategyGlobalFilters.value).map(
      ([group, values]) => {
        const totalCount = strategyAvailableFilters.value[group].length;

        // Spread values to prevent result being a Proxy object
        return [group, values.length === totalCount ? [] : [...values]];
      }
    );

    return Object.fromEntries(entries);
  });

  // Tiers ***************************************

  const tiers = ref<Tiers>();

  function createTierItems(tiers: string[]): SelectItem[] {
    // Create an array of numbers to use as a mix value
    const spreadColors = spreadBetweenRange(0, 1, tiers.length)
      // Create a range of colors from blue to red
      .map((mix) => colorMixer.mix(theme.blue[500], theme.red[500], mix));

    return tiers.map((tier, i) => ({
      title: tier,
      value: tier,
      color: spreadColors[i],
    }));
  }

  const availablePbTiers = computed(() =>
    createTierItems(
      tiers.value?.prime_broker_tiers[activePrimeBrokers.value[0]] ?? []
    )
  );

  const availableKsTiers = computed(() =>
    createTierItems(tiers.value?.kayenta_spread_tiers ?? [])
  );

  // FX Rates ************************************

  const fxRates = ref<FxData>();

  // Accounts ************************************

  const accounts = shallowRef<Account[]>([]);
  const strategyAccounts = shallowRef<StrategyAccount[]>([]);

  const groupRelationships = computed<Relationships[]>(() =>
    accounts.value.map((account) => [
      account.title,
      account.fund.title,
      account.prime_broker.title,
    ])
  );

  const strategyGroupRelationships = computed<StrategyRelationships[]>(() =>
    strategyAccounts.value.map((account) => [
      account.title,
      account.strategy.title,
      account.fund.title,
      account.prime_broker.title,
    ])
  );

  // Dispersion Percentiles **********************

  const dispersionPercentiles = ref<StockLoanBenchmarkingPercentiles>();

  // Profile *************************************

  const userProfile = ref<Profile>();

  const isSuperuserOverride = ref(false);
  const isStaffOverride = ref(false);

  const subscriptions = ref<Record<Subscription, boolean>>({
    wallet: false,
    stock_loan_data: false,
    billing_compare: false,
    portfolio_add_on: false,
    broker_analysis: false,
    strategies: false,
    optimizations: false,
  });

  const isDummyStrategyData = ref(false);
  // If on Demo environment default to use dummy data for strategies
  watchEffect(() => {
    isDummyStrategyData.value = envMeta.value.client === "Demo";
  });

  return {
    // User settings
    defaultDateRange,
    selectedView,
    splitBy,
    isMultisort,
    showPositionCurrency,
    tiering,
    zoomYAxis,
    showPrecision,
    graphSmoothing,
    //
    isSetupComplete,
    cachedData,
    availablePrimeBrokers,
    availableAccounts,
    availableFunds,
    availableCurrencies,
    availablePbTiers,
    availableKsTiers,
    globalFilters,
    globalFilterParams,
    userProfile,
    pendingRequests,
    envMeta,
    isSuperuserOverride,
    isStaffOverride,
    subscriptions,
    featureFlags,
    logTrackEventsToConsole,
    logTrackEventsToAws,
    itemsWithColor,
    getItemColor,
    availableFilters,
    globalCurrency,
    isSingleCurrencyFilter,
    activePrimeBrokers,
    drilldownView,
    tiers,
    fxRates,
    accounts,
    dispersionPercentiles,
    groupRelationships,
    strategyAccounts,
    strategySplitBy,
    strategySelectedView,
    strategyGlobalFilters,
    strategyAvailableFilters,
    strategyGroupRelationships,
    strategyGlobalFilterParams,
    strategyIsSingleCurrencyFilter,
    availableStrategies,
    availableStrategyAccounts,
    isDummyStrategyData,
    usePrecision,
  };
});
