<template>
  <v-select
    v-bind="$attrs"
    v-model="modelValue"
    v-model:menu="menu"
    :item-title
    :item-value
    :items
    density="comfortable"
    :menu-icon="mdiChevronDown"
  >
    <template #item="slotProps">
      <slot
        name="item"
        v-bind="slotProps"
      >
        <v-list-item
          v-bind="slotProps.props"
          :disabled="slotProps.item.raw.disabled || slotProps.item.raw.header"
          :class="{ header: slotProps.item.raw.header }"
        />
      </slot>
    </template>
    <template
      v-for="(slot, index) of slotNames"
      :key="index"
      #[slot]="slotProps"
    >
      <slot
        :name="slot"
        v-bind="slotProps"
      />
    </template>
  </v-select>
</template>

<script lang="ts" setup>
import { mdiChevronDown } from "@mdi/js";
import type { ComponentProps } from "vue-component-type-helpers";
import { VSelect } from "vuetify/lib/components/index.mjs";

import { useTrackEvent } from "@/composables/useTrackEvent";
import { useSlots } from "#imports";

type VSelectProps = ComponentProps<typeof VSelect>;
const props = withDefaults(
  defineProps<{
    itemTitle?: VSelectProps["itemTitle"];
    itemValue?: VSelectProps["itemValue"];
    trackEvent: TrackEventProperties | undefined;
    items: VSelectProps["items"];
  }>(),
  {
    itemTitle: "title",
    itemValue: "value",
  }
);

// Types not working properly so using a simple dictionary
const slots = useSlots() as Dictionary;

const slotNames = Object.keys(slots).filter((k) => k !== "item");

const modelValue = defineModel<VSelectProps["modelValue"] | undefined>({
  required: true,
});

const menu = defineModel<VSelectProps["menu"]>("menu", {
  required: false,
});

if (props.trackEvent) {
  const trackEventValue = computed(() => {
    return (
      props.items?.find(
        (item) =>
          item === modelValue.value ||
          item[props.itemValue as string] === modelValue.value
      )?.[props.itemTitle as string] ?? modelValue.value
    );
  });

  useTrackEvent().watch({
    value: trackEventValue,
    element: "select",
    ...props.trackEvent,
  });
}
</script>

<style scoped>
:deep(label) {
  transform: translateY(-12px) !important;
}
:deep(.v-select__selection-text) {
  font-size: 0.875rem;
}
</style>

<style>
.v-list-item {
  min-height: 2.5rem !important;
}
.v-list-item-title {
  font-size: 0.875rem !important;
}
.header .v-list-item-title {
  font-size: 0.75rem !important;
}
</style>
