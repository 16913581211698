<template>
  <div
    class="flex h-6 cursor-pointer items-center rounded-full bg-accent px-2 text-[0.65rem] text-white"
  >
    <span> Beta </span>
    <v-tooltip
      location="top"
      open-delay="400"
      activator="parent"
    >
      <p>This feature is in BETA</p>
      <p>
        It may be using dummy data, and may not be accurate. Changes may occur
        as we gain feedback.
      </p>
    </v-tooltip>
  </div>
</template>
