<template>
  <v-app-bar
    color="background"
    :height="56"
    :extension-height="48"
    app
    flat
    class="z-20"
  >
    <AppProgressBar />

    <div class="flex w-full px-2">
      <div class="mr-auto flex items-center gap-2 overflow-hidden px-2">
        <h1>
          {{ displayTitle }}
        </h1>
        <BetaChip v-if="beta" />
      </div>

      <div
        id="app-toolbar__top-right"
        class="contents"
      >
        <div
          v-for="routeName in allRoutes"
          v-show="routeName === toKebab(String(route.name))"
          :key="routeName"
          class="contents"
          :class="routeName"
        />
      </div>

      <AppInlineGlossary />
      <AppSettings />
    </div>

    <template #extension>
      <div class="flex size-full items-start gap-4 px-4">
        <ToolbarTeleportContainer id="app-toolbar__left" />
        <div class="grow" />
        <ToolbarTeleportContainer id="app-toolbar__right">
          <!-- Only show one warning at a time -->
          <div
            class="highcharts-smoothing-alert self-center *:hidden [&>*:first-child]:flex"
          />
        </ToolbarTeleportContainer>
      </div>
    </template>
  </v-app-bar>
</template>

<script lang="ts" setup>
import { useDisplay } from "vuetify";

import { toKebab } from "#imports";

import ToolbarTeleportContainer from "./ToolbarTeleportContainer.vue";

const route = useRoute();

const router = useRouter();
const allRoutes = router
  .getRoutes()
  .map((r) => (r.name ? toKebab(r.name as string) : false))
  .filter((e) => typeof e === "string");

// Breakpoints
const { mdAndDown } = useDisplay();

const displayTitle = computed(() => {
  const title = route.name?.toString() ?? "";
  return mdAndDown.value ? title.replace(/^.+:/, "") : title;
});

const beta = computed(() => {
  return route.meta?.beta;
});
</script>

<style scoped>
:is(#app-toolbar__left, #app-toolbar__right) :deep(> div > *) {
  flex-grow: 0;
}

:deep(.v-toolbar__content) {
  padding-block-start: 0.5rem;
}
</style>
