import type EventBus from "@/utils/EventBus";

// Symbols are not injected properly in tests, so we need to use a string as a key
export const componentBusKey: InjectionKey<EventBus> = import.meta.env.TEST
  ? ("componentBusKey" as unknown as InjectionKey<EventBus>)
  : Symbol("componentBusKey");

export const sectionTitleKey: InjectionKey<
  MaybeRefOrGetter<string | undefined>
> = import.meta.env.TEST
  ? ("sectionTitleKey" as unknown as InjectionKey<
      MaybeRefOrGetter<string | undefined>
    >)
  : Symbol("sectionTitleKey");
