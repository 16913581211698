<template>
  <div>
    <div class="mb-2 flex h-8 items-center text-xs text-gray-500">
      Filtering
      <v-chip
        v-if="
          selectedValues.length && selectedValues.length < itemValues.length
        "
        class="ml-4 text-accent"
        size="small"
        closable
        @click:close="selectedValues = []"
      >
        {{ selectedValues.length }}
      </v-chip>
    </div>
    <v-list class="max-h-80 overflow-scroll pt-0">
      <CheckboxSelectItem
        v-if="itemValues.length > 2"
        title="Select All"
        class="sticky top-0 z-10 mb-2 border-b bg-white"
        :model-value="allSelected"
        @select="selectAll"
      />
      <CheckboxSelectItem
        v-for="[item, { text, color }] in itemValues"
        :key="item"
        :class="{ italic: item === NULL_VALUE }"
        :title="text"
        :color
        :model-value="selectedValues.includes(item)"
        @select="handleSelect(item)"
      />
    </v-list>
  </div>
</template>

<script setup lang="ts" generic="State extends string">
import CheckboxSelectItem from "../_App/CheckboxSelectItem.vue";
import { createStringFilter } from "./DataTableFilterString.vue";
import type { DataTableColumn, DataTableItem } from "./dataTableTypes";
import { useTableOptions } from "./useTableOptions";

const props = defineProps<{
  column: DataTableColumn;
  items: DataTableItem[] | null;
  states: Map<State, { text: string; color: string }>;
}>();

const options = useTableOptions();

const selectedValues = ref(
  options.columnFilters[props.column.value]?.state.values ?? []
) as Ref<(State | NullState)[]>;

function handleSelect(item: State | NullState) {
  selectedValues.value = insertOrRemoveItem(selectedValues.value, item);
}

useTrackEvent().watch({
  name: "Filter",
  title: props.column.title,
  value: () => selectedValues.value.length,
});

const NULL_VALUE = "";
type NullState = typeof NULL_VALUE;

const itemValues = computed(() => {
  const stateValues: [State | NullState, { text: string; color: string }][] = [
    ...props.states.entries(),
  ];
  const includesEmptyValues = props.items?.some(
    (item) => item[props.column.value] === null
  );

  // Use empty string as null value so we can re-use createStringFilter
  if (includesEmptyValues) {
    stateValues.push([NULL_VALUE, { text: "No Value", color: "" }]);
  }
  return stateValues;
});

const allSelected = computed(() =>
  itemValues.value
    .map(([e]) => e)
    .every((e) => selectedValues.value.includes(e))
);

function selectAll() {
  selectedValues.value = allSelected.value
    ? []
    : itemValues.value.map(([e]) => e);
}

const columnFilter = computed(() => {
  return createStringFilter(props.column.value, {
    values: allSelected.value ? [] : selectedValues.value,
  });
});

const columnFilters = computed(() => {
  const restFilters = omit(options.columnFilters, [props.column.value]);

  return columnFilter.value
    ? {
        ...restFilters,
        [props.column.value]: columnFilter.value,
      }
    : restFilters;
});

watch(columnFilter, (columnFilter) => {
  const externalFilter = options.columnFilters[props.column.value];

  if (deepEqual(externalFilter?.state, columnFilter?.state) === false) {
    options.columnFilters = columnFilters.value;
  }
});
</script>

<style scoped>
.v-list-item {
  font-size: 0.875rem;
  min-height: 2.5rem;
  padding-left: 0 !important;
}

:deep(.v-checkbox-btn) {
  --v-selection-control-size: 21px;
}
</style>
