import dayjs from "dayjs";

import { sectionTitleKey } from "@/components/ContentBlock/contentBlockInjectionKeys";
import { useAppStore } from "~/store/app";
import { getBreakpoint } from "~/utils/getBreakpoint";

export type TrackEventProperties = TriggerOptions | undefined;

export type EventTracker = (args: Dictionary<unknown>) => void;

export type TriggerOptions = {
  name: string;
} & Dictionary<MaybeRefOrGetter<unknown>>;

export type TriggerConfig = {
  immediate?: boolean;
};

export type WatchOptions = {
  /** Ignore trigger if another trigger occurs within the debounceMs. default: 0ms */
  debounceMs?: number;
  /** Time after initialisation that value changes will start to be tracked. default: 0ms  */
  startTrackMs?: number;
};

export function useTrackEvent() {
  const route = useRoute();
  const appStore = useAppStore();

  const section = inject(sectionTitleKey, undefined);

  function triggerTrackEvent(options: TriggerOptions, config?: TriggerConfig) {
    const eventData: Dictionary<any> = {
      page: route.meta.name ?? "",
      section: toValue(section),
      local_time: dayjs().format("HH:mm:ss"),
      utc_time: dayjs().utc().format("HH:mm:ss"),
      screen_size: `${window.innerWidth}x${window.innerHeight}`,
      breakpoint: getBreakpoint(window.innerWidth),
    };

    for (const key in options) {
      eventData[key] = toValue(options[key]);
    }

    if (appStore.logTrackEventsToAws) {
      const { awsRum } = useAwsRum();
      if (!awsRum) {
        console.warn("AWS RUM not loaded - event not tracked", eventData);
        return;
      }

      awsRum.recordEvent("ui-interaction", eventData);

      if (config?.immediate) {
        awsRum.dispatch();
      }
    }

    if (appStore.logTrackEventsToConsole) {
      console.info(`Track Event: ${eventData.name}`, eventData);
    }
  }

  function watchTrackEvent(
    triggerOptions: TriggerOptions,
    { debounceMs, startTrackMs }: WatchOptions = {
      debounceMs: 5000,
      startTrackMs: 1000,
    }
  ) {
    const triggerFn = () =>
      triggerTrackEvent(triggerOptions, { immediate: false });

    const debouncedFn = debounceMs
      ? debounce(triggerFn, debounceMs)
      : triggerFn;

    const reactiveTriggerOptions = reactive(triggerOptions);

    const startWatcher = () => {
      // Watch getter functions
      for (const key in triggerOptions) {
        const watchVal = triggerOptions[key as keyof typeof triggerOptions];
        if (typeof watchVal !== "function") continue;

        watch(watchVal, debouncedFn, { flush: "sync" });
      }

      // Watch reactive values
      watch(reactiveTriggerOptions, debouncedFn, { flush: "sync" });
    };

    if (startTrackMs) {
      setTimeout(startWatcher, startTrackMs);
    } else {
      startWatcher();
    }
  }

  return { trigger: triggerTrackEvent, watch: watchTrackEvent };
}
