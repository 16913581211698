<template>
  <v-snackbar
    class="m-2"
    :model-value="errorList.length > 0"
    :width="600"
    :timeout="-1"
    :color="theme.red[500]"
    location="top right"
    transition="slide-x-reverse-transition"
    rounded
    multi-line
  >
    <div
      v-resize-observer="getErrorSnackbarHeight"
      class="flex justify-between gap-8 text-xs font-bold leading-8"
    >
      <div
        v-sane-html="error"
        class="contents"
      />
      <UiIconBtn
        variant="neutral"
        :track-event="undefined"
        size="small"
        @click="errorList.pop()"
      >
        {{ mdiClose }}
      </UiIconBtn>
    </div>
  </v-snackbar>

  <v-snackbar
    class="m-2"
    :model-value="displayTooltip"
    :width="600"
    :timeout="-1"
    color="surface"
    location="top right"
    transition="slide-x-reverse-transition"
    rounded
    multi-line
    :style="{ top: `${offset}px` }"
  >
    <div
      v-sane-html="tooltip"
      class="text-xs"
    />
  </v-snackbar>
</template>

<script lang="ts" setup>
import { mdiClose } from "@mdi/js";

import { theme } from "#imports";

const tooltip = ref<string>();
const displayTooltip = ref(false);

GlobalBus.$on("snackbar:tooltip", (content: string | null) => {
  if (content) {
    tooltip.value = content;
    displayTooltip.value = true;
    return;
  }
  displayTooltip.value = false;
});

const error = ref<string>();
const errorList = ref<string[]>([]);

GlobalBus.$on("snackbar:error", (content) => {
  error.value = content;
  errorList.value.push(content);
});

const offset = ref(0);

function getErrorSnackbarHeight([entry]: ResizeObserverEntry[]) {
  const parent = entry.target.parentElement;

  offset.value = parent ? parent.clientHeight + 8 : 0;
}

watchEffect(() => {
  if (errorList.value.length) {
    error.value = errorList.value.at(-1)!;
  } else {
    offset.value = 0;
  }
});
</script>

<style>
.v-snackbar {
  --v-layout-top: 0 !important;
}
</style>
