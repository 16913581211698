<template>
  <div class="contents">
    <div
      v-for="routeName in allRoutes"
      v-show="routeName === toKebab(String(route.name))"
      :key="routeName"
      class="contents"
      :class="routeName"
    >
      <slot />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { toKebab } from "#imports";

const route = useRoute();

const router = useRouter();
const allRoutes = router
  .getRoutes()
  .map((r) => (r.name ? toKebab(r.name as string) : false))
  .filter((e) => typeof e === "string");
</script>
