<template>
  <template v-if="showItem">
    <v-list-group
      v-if="isSuperuserOverride || isSubscribed"
      color="primary"
      :value="toKebab(title)"
      role="group"
      :aria-label="title"
    >
      <template #activator="{ props: activatorProps }">
        <NavDrawerItem
          v-bind="activatorProps"
          :title
          :icon
          :admin-only
          :subscription
          :beta
        >
          <template #append>
            <v-icon class="ml-4 text-xl transition-[rotate]">
              {{ mdiChevronRight }}
            </v-icon>
          </template>
        </NavDrawerItem>
      </template>

      <slot />
    </v-list-group>

    <NavDrawerItem
      v-else
      :title
      :icon
      :admin-only
      :subscription
      :to="`/subscription-needed-${redirect}`"
    />
  </template>
</template>

<script lang="ts" setup>
import { mdiChevronRight } from "@mdi/js";

import { toKebab } from "#imports";
import { useAppStore } from "~/store/app";

const props = defineProps<{
  title: string;
  icon: string;
  adminOnly?: boolean;
  subscription?: Subscription;
  redirect?: string;
  hideIfUnsubscribed?: boolean;
  beta?: boolean;
}>();

const { subscriptions, isSuperuserOverride } = storeToRefs(useAppStore());

const isSubscribed = computed(
  () => !props.subscription || subscriptions.value[props.subscription]
);

const showItem = computed(() => {
  if (isSuperuserOverride.value) return true;
  if (props.adminOnly) return false;
  if (isSubscribed.value) return true;
  return !props.hideIfUnsubscribed;
});
</script>

<style scoped>
.v-list-group:has(.v-list-item--active) {
  background-color: color-mix(
    in srgb,
    theme("colors.primary.DEFAULT") 5%,
    white
  );

  :deep(.v-list-item:not(.v-list-item--active):hover) {
    background-color: color-mix(
      in srgb,
      theme("colors.primary.DEFAULT") 10%,
      white
    );
  }
}

.v-list-group--open .v-icon {
  rotate: 90deg;
}

.v-list-group--open {
  margin-bottom: 1rem;
}

:deep(.v-list-group__items) {
  .v-list-item {
    height: 2.75rem;
    padding-left: 1rem !important;
    position: relative;
  }

  .v-list-item__content {
    margin-left: 5rem;
  }

  .v-list-item__prepend {
    position: absolute;
    top: 50%;
    translate: 0% -50%;
  }
}
</style>
